import { IconType } from 'helpers/icons'
import { PermissionsType } from 'services/users'

export type LinkType = {
  title: string
  iconName: IconType
  path?: string
  sublinks?: {
    title?: string
    url?: string
  }[]
  permissions?: PermissionsType[]
}

export const links: LinkType[] = [
  {
    title: 'Home',
    path: '/',
    iconName: 'HomeIcon',
    sublinks: []
  },
  {
    title: 'Personalização',
    path: '/personalizar',
    iconName: 'ComputerIcon',
    sublinks: [
      { title: 'Personalizar Home', url: '/personalizar-home' },
      { title: 'Redes sociais', url: '/personalizar-redes-sociais' },
      { title: 'Lojas ativas', url: '/personalizar-lojas-ativas' }
    ],
    permissions: [PermissionsType.PERSONALIZACAO]
  },
  {
    title: 'Política de Precificação',
    path: '/politicas-de-precificacao',
    iconName: 'TagDollarIcon',
    sublinks: [],
    permissions: [PermissionsType.PRECIFICACAO]
  },
  {
    title: 'Gestão de SEO',
    path: '/gestao-de-seo',
    iconName: 'RadioIcon',
    permissions: [PermissionsType.SEO],
      sublinks: [
      { title: 'Páginas Estáticas', url: '/seo-paginas-estaticas' },
      { title: 'Páginas de Marcas', url: '/seo-paginas-de-marcas' },
    ],
  },
  {
    title: 'Usuários',
    path: '/usuarios',
    iconName: 'UsersIcon',
    sublinks: [
      { title: 'Usuário Externos', url: '/usuarios-externos' },
      { title: 'Usuário Internos', url: '/usuarios-internos' }
    ],
    permissions: [PermissionsType.USUARIOS]
  },
  {
    title: 'Editorial',
    path: '/editorial',
    iconName: 'BookOpenIcon',
    sublinks: [
      { title: 'Canais', url: '/editorial-canais' },
      { title: 'Escritores', url: '/editorial-escritores' },
      { title: 'Blog', url: '/editorial-blog' }
    ],
    permissions: [PermissionsType.EDITORIAL]
  },
  {
    title: 'Registro de Atividades',
    path: '/registro-de-atividades',
    iconName: 'NotesClockIcon',
    sublinks: []
  }
]
