import { List, styled } from '@material-ui/core'
import { StyledThemeProp } from '@sagacarmi/seminovos-design-system/core/theme'

export const SideBarWrapper = styled('aside')(
  ({ theme: { palette } }: StyledThemeProp) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'sticky',
    top: '60px',
    height: 'calc(100vh - 60px)',
    backgroundColor: palette.neutral.black.white1,
    borderRight: `1px solid ${palette.neutral.grey.gray5}`,
    padding: '9px 0 24px 0'
  })
)

export const CustomList = styled(List)({
  display: 'flex',
  flexDirection: 'column',
  gap: '18px',
  overflowY: 'auto',
  overflowX: 'hidden'
})

export const Signout = styled('div')(
  ({ theme: { typography } }: StyledThemeProp) => ({
    display: 'flex',
    gap: '16px',
    padding: '8px 16px',
    cursor: 'pointer',
    maxWidth: '104px',
    marginLeft: '4px',

    '& button': {
      ...typography.body.desktop.regular3,
      fontWeight: 400
    }
  })
)

export const Powered = styled('div')(
  ({ theme: { breakpoints, typography } }: StyledThemeProp) => ({
    [breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',

      '& div': {
        ...typography.body.desktop.regular3,
        background: '#000000',
        minWidth: '195px',
        padding: '12px',
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        borderRadius: '4px',
        color: '#FFFFFF',
        justifyContent: 'center'
      }
    },
    display: 'none'
  })
)

export const SideNavBar = styled('nav')(
  ({ isActive, hasSublink }: { isActive: boolean; hasSublink: boolean }) => ({
    '& > div': {
      borderLeft: isActive
        ? `${hasSublink ? 0 : '4px'} solid #000000`
        : `${hasSublink ? 0 : '4px'} solid transparent`
      // backgroundColor: isActive ? '#F8F8F8' : 'transparent'
    },

    '& a': {
      display: 'flex',
      alignItems: 'center',
      gap: '16px'
    }
  })
)

export const SubLinkWrapped = styled('ul')({
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  gap: '18px'
})

export const SubLink = styled('li')(({ isActive }: { isActive: boolean }) => ({
  fontWeight: isActive ? 700 : 400
}))
