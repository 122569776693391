import { ListItem } from '@material-ui/core'
import { Icon } from '@sagacarmi/seminovos-design-system/core'
import CustomAccordionComponent from 'components/CustomAccordion/CustomAccordionComponent'
import { LinkType } from 'constants/sidebarLink'
import { getIcon } from 'helpers/icons'
import { Link, useLocation } from 'react-router-dom'
import * as Style from './style'

const SideBarComponent = ({ title, path, iconName, sublinks }: LinkType) => {
  const location = useLocation()

  const isLinkActive = () => {
    if (location.pathname.includes(path) && title !== 'Home') {
      return true
    }

    if (location.pathname === '/' && title === 'Home') {
      return true
    }

    return false
  }

  const hasSublinks = sublinks.length > 0

  const formatPath = hasSublinks ? sublinks[0].url : path

  return (
    <Style.SideNavBar isActive={isLinkActive()} hasSublink={hasSublinks}>
      {hasSublinks ? (
        <CustomAccordionComponent
          summaryTitle={
            <ListItem>
              <Link to={formatPath}>
                <Icon icon={getIcon(iconName)} />
                {title}
              </Link>
            </ListItem>
          }
          isSideBar
          maxHeight="40px"
          isActive={isLinkActive()}
        >
          <Style.SubLinkWrapped>
            {sublinks.map((sublink) => (
              <Style.SubLink
                key={sublink.title}
                isActive={location.pathname.includes(sublink.url)}
              >
                <Link to={sublink.url}>{sublink.title}</Link>
              </Style.SubLink>
            ))}
          </Style.SubLinkWrapped>
        </CustomAccordionComponent>
      ) : (
        <div>
          <ListItem>
            <Link to={formatPath}>
              <Icon icon={getIcon(iconName)} />
              {title}
            </Link>
          </ListItem>
        </div>
      )}
    </Style.SideNavBar>
  )
}

export default SideBarComponent
