import HeaderComponent from 'components/Header/HeaderComponent'
import { Outlet } from 'react-router-dom'
import SideBarContainer from '../SideBarComponent'
import * as Style from './style'

const ContainerAdminComponent = () => {
  return (
    <Style.Container>
      <HeaderComponent />
      <SideBarContainer />
      <Style.Main>
        <Outlet />
      </Style.Main>
    </Style.Container>
  )
}

export default ContainerAdminComponent
