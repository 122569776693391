import apm from 'helpers/apm'
import ReactDOM from 'react-dom'
import App from './App'
// import './reactotron'

if (process.env.REACT_APP_START_APM === 'enabled') {
  apm.init()
}

ReactDOM.render(<App />, document.getElementById('root'))
