import Logo from 'assets/icon/logo.png'
import { useUserProfile } from 'context/UserProfileContext'
import { Link } from 'react-router-dom'
import * as Style from './style'

// const links = [
//   { name: 'Agenda', id: 1, href: '#' },
//   { name: 'Contatos', id: 2, href: '#' }
// ]

const HeaderComponent = () => {
  const { userProfile } = useUserProfile()

  const firstName = userProfile?.name?.split(' ')?.[0]

  return (
    <Style.HeaderWrapper>
      <Style.Header>
        <Link to="/">
          <img src={Logo} alt="Logo primeira mão" />
        </Link>
        {/* <nav>
          <Style.NavLink>
            {links.map((link) => (
              <li key={link.id}>
                <a href={link.href}>{link.name}</a>
              </li>
            ))}
            <Button label="Visualizar site" variant="outlinedSecondary" />
          </Style.NavLink>
        </nav> */}
        <Style.UserSpace>
          <Style.UserName>Olá, {firstName}</Style.UserName>
        </Style.UserSpace>
      </Style.Header>
    </Style.HeaderWrapper>
  )
}

export default HeaderComponent
