/* eslint-disable no-console */
import Keycloak from 'keycloak-js'

const auth = new Keycloak(
  process.env.REACT_APP_ENV === 'development'
    ? '/keycloak-test.json'
    : '/keycloak.json'
)

let authenticated = false

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let user = {}

const login = async () => {
  try {
    authenticated = await auth
      .init({
        onLoad: 'login-required',
        checkLoginIframe: false
      })
      .then((authenticated) => {
        return authenticated
      })
      .catch(() => {
        return false
      })

    return authenticated
  } catch (error) {
    return false
  }
}

const getProfile = async () => {
  try {
    await auth
      .loadUserProfile()
      .then((profile) => {
        user = profile
      })
      .catch(() => {
        console.log('Erro inesperado...')
      })

    return user
  } catch (error) {
    return false
  }
}

const getToken = () => {
  return auth.token
}

const isLogged = () => {
  const token = getToken()

  return !!token
}

const logout = () => {
  auth.logout({
    redirectUri: window.location.origin
  })
}

export { getProfile, getToken, isLogged, login, logout }

export default auth
