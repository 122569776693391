import { ReactComponent as AllRoundIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/360.svg'
import { ReactComponent as ActivityIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/activity.svg'
import { ReactComponent as AirplayIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/airplay.svg'
import { ReactComponent as AlertCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/alert-circle.svg'
import { ReactComponent as AlertOctagonIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/alert-octagon.svg'
import { ReactComponent as AlertTriangleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/alert-triangle.svg'
import { ReactComponent as AlignCenterIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/align-center.svg'
import { ReactComponent as AlignJustifyIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/align-justify.svg'
import { ReactComponent as AlignLeftIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/align-left.svg'
import { ReactComponent as AlignRightIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/align-right.svg'
import { ReactComponent as AnchorIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/anchor.svg'
import { ReactComponent as ApertureIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/aperture.svg'
import { ReactComponent as AppleFullIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/apple-full.svg'
import { ReactComponent as AppleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/apple.svg'
import { ReactComponent as ArchiveIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/archive.svg'
import { ReactComponent as ArrowDownCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-down-circle.svg'
import { ReactComponent as ArrowDownLeftIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-down-left.svg'
import { ReactComponent as ArrowDownRightIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-down-right.svg'
import { ReactComponent as ArrowDownIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-down.svg'
import { ReactComponent as ArrowLeftCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-left-circle.svg'
import { ReactComponent as ArrowLeftIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-left.svg'
import { ReactComponent as ArrowRightCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-right-circle.svg'
import { ReactComponent as ArrowRightIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-right.svg'
import { ReactComponent as ArrowUpCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-up-circle.svg'
import { ReactComponent as ArrowUpDownIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/Arrow-up-down.svg'
import { ReactComponent as ArrowUpLeftIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-up-left.svg'
import { ReactComponent as ArrowUpRightIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-up-right.svg'
import { ReactComponent as ArrowUpIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow-up.svg'
import { ReactComponent as ArrowFilledDownIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow_filled_down.svg'
import { ReactComponent as ArrowFilledUpIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/arrow_filled_up.svg'
import { ReactComponent as AtSignIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/at-sign.svg'
import { ReactComponent as AwardIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/award.svg'
import { ReactComponent as BarChart2Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/bar-chart-2.svg'
import { ReactComponent as BarChartIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/bar-chart.svg'
import { ReactComponent as BarCodeSearchIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/barcode-search.svg'
import { ReactComponent as BatteryChargingIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/battery-charging.svg'
import { ReactComponent as BatteryIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/battery.svg'
import { ReactComponent as BellOffIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/bell-off.svg'
import { ReactComponent as BellIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/bell.svg'
import { ReactComponent as BlankIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/blank.svg'
import { ReactComponent as BluetoothIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/bluetooth.svg'
import { ReactComponent as BoldIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/bold.svg'
import { ReactComponent as BookOpenIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/book-open.svg'
import { ReactComponent as BookIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/book.svg'
import { ReactComponent as BookmarkIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/bookmark.svg'
import { ReactComponent as BoxIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/box.svg'
import { ReactComponent as BriefcaseIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/briefcase.svg'
import { ReactComponent as CalendarCheck1Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/calendar-check-1.svg'
import { ReactComponent as CalendarCheckIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/calendar-check.svg'
import { ReactComponent as CalendarClockIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/calendar-clock.svg'
import { ReactComponent as CalendarIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/calendar.svg'
import { ReactComponent as CameraOffIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/camera-off.svg'
import { ReactComponent as CameraIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/camera.svg'
import { ReactComponent as CarPerfectIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/car-perfect.svg'
import { ReactComponent as CarRepairBottomIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/car-repair-bottom.svg'
import { ReactComponent as CarRepairChecklistIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/car-repair-checklist-1.svg'
import { ReactComponent as CarRetroIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/car-retro-2.svg'
import { ReactComponent as CarSideIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/Car-side.svg'
import { ReactComponent as CardsIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/cards.svg'
import { ReactComponent as CashPaymentBillsIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/cash-payment-bills-1.svg'
import { ReactComponent as CastIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/cast.svg'
import { ReactComponent as CheckCircle1Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/check-circle-1.svg'
import { ReactComponent as CheckCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/check-circle.svg'
import { ReactComponent as CheckSquareIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/check-square.svg'
import { ReactComponent as CheckIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/check.svg'
import { ReactComponent as ChevronDownIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/chevron-down.svg'
import { ReactComponent as ChevronLeftIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/chevron-right.svg'
import { ReactComponent as ChevronUpIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/chevron-up.svg'
import { ReactComponent as ChevronsDownIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/chevrons-down.svg'
import { ReactComponent as ChevronsLeftIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/chevrons-left.svg'
import { ReactComponent as ChevronsRightIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/chevrons-right.svg'
import { ReactComponent as ChevronsUpIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/chevrons-up.svg'
import { ReactComponent as ChromeIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/chrome.svg'
import { ReactComponent as CircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/circle.svg'
import { ReactComponent as ClipboardIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/clipboard.svg'
import { ReactComponent as ClockIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/clock.svg'
import { ReactComponent as CloseCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/close-circle.svg'
import { ReactComponent as CloseOctagonIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/close-octagon.svg'
import { ReactComponent as CloseSquareIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/close-square.svg'
import { ReactComponent as CloseIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/close.svg'
import { ReactComponent as CloudRainIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/cloud-rain.svg'
import { ReactComponent as CloudSnowIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/cloud-snow.svg'
import { ReactComponent as CloudIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/cloud.svg'
import { ReactComponent as CodeIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/code.svg'
import { ReactComponent as CodepenIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/codepen.svg'
import { ReactComponent as CodesandboxIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/codesandbox.svg'
import { ReactComponent as CoffeeIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/coffee.svg'
import { ReactComponent as ColumnsIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/columns.svg'
import { ReactComponent as CommandIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/command.svg'
import { ReactComponent as CompassIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/compass.svg'
import { ReactComponent as ComponentIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/component.svg'
import { ReactComponent as ComputerIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/computer.svg'
import { ReactComponent as CopyIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/copy.svg'
import { ReactComponent as CornerDownLeftIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/corner-down-left.svg'
import { ReactComponent as CornerDownRightIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/corner-down-right.svg'
import { ReactComponent as CornerLeftDownIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/corner-left-down.svg'
import { ReactComponent as CornerLeftUpIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/corner-left-up.svg'
import { ReactComponent as CornerRightDownIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/corner-right-down.svg'
import { ReactComponent as CornerRightUpIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/corner-right-up.svg'
import { ReactComponent as CornerUpLeftIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/corner-up-left.svg'
import { ReactComponent as CornerUpRightIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/corner-up-right.svg'
import { ReactComponent as CpuIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/cpu.svg'
import { ReactComponent as CreditCardIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/credit-card.svg'
import { ReactComponent as CropIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/crop.svg'
import { ReactComponent as CrosshairIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/crosshair.svg'
import { ReactComponent as DashboardIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/dashboard.svg'
import { ReactComponent as DatabaseIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/database.svg'
import { ReactComponent as DeleteIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/delete.svg'
import { ReactComponent as DeliveryTruckIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/delivery-truck.svg'
import { ReactComponent as DiscIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/disc.svg'
import { ReactComponent as DiscountBubbleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/discount-bubble.svg'
import { ReactComponent as DiscountIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/discount.svg'
import { ReactComponent as Discount1Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/discount1.svg'
import { ReactComponent as DivideCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/divide-circle.svg'
import { ReactComponent as DivideSquareIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/divide-square.svg'
import { ReactComponent as DivideIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/divide.svg'
import { ReactComponent as DollarSignIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/dollar-sign.svg'
import { ReactComponent as DownloadCloudIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/download-cloud.svg'
import { ReactComponent as DownloadIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/download.svg'
import { ReactComponent as DribbbleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/dribbble.svg'
import { ReactComponent as DropletIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/droplet.svg'
import { ReactComponent as Edit2Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/edit-2.svg'
import { ReactComponent as Edit3Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/edit-3.svg'
import { ReactComponent as EditIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/edit.svg'
import { ReactComponent as EngineIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/engine.svg'
import { ReactComponent as Engine1Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/engine1.svg'
import { ReactComponent as ExternalLinkIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/external-link.svg'
import { ReactComponent as EyeOffIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/eye-off.svg'
import { ReactComponent as EyeIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/eye.svg'
import { ReactComponent as Facebook1Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/facebook-1.svg'
import { ReactComponent as FacebookIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/facebook.svg'
import { ReactComponent as FastForwardIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/fast-forward.svg'
import { ReactComponent as FeatherIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/feather.svg'
import { ReactComponent as FigmaIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/figma.svg'
import { ReactComponent as FileMinusIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/file-minus.svg'
import { ReactComponent as FilePlusIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/file-plus.svg'
import { ReactComponent as FileTextIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/file-text.svg'
import { ReactComponent as FileIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/file.svg'
import { ReactComponent as FilmIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/film.svg'
import { ReactComponent as FilterSettingsIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/filter-settings.svg'
import { ReactComponent as FilterIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/filter.svg'
import { ReactComponent as FlagIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/flag.svg'
import { ReactComponent as FolderMinusIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/folder-minus.svg'
import { ReactComponent as FolderPlusIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/folder-plus.svg'
import { ReactComponent as FolderIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/folder.svg'
import { ReactComponent as FrownIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/frown.svg'
import { ReactComponent as FuelIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/fuel.svg'
import { ReactComponent as GiftIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/gift.svg'
import { ReactComponent as Gift1Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/gift1.svg'
import { ReactComponent as GlobeIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/globe.svg'
import { ReactComponent as GoogleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/google.svg'
import { ReactComponent as Google2Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/google2.svg'
import { ReactComponent as GridIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/grid.svg'
import { ReactComponent as HandshakeIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/Handshake.svg'
import { ReactComponent as HardDriveIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/hard-drive.svg'
import { ReactComponent as HashIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/hash.svg'
import { ReactComponent as HeadphonesIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/headphones.svg'
import { ReactComponent as HeartFullIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/heart-full.svg'
import { ReactComponent as HeartIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/heart.svg'
import { ReactComponent as HelpCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/help-circle.svg'
import { ReactComponent as HexagonIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/hexagon.svg'
import { ReactComponent as HomeIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/home.svg'
import { ReactComponent as ImageIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/image.svg'
import { ReactComponent as InboxIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/inbox.svg'
import { ReactComponent as InfoIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/info.svg'
import { ReactComponent as InstagramIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/instagram.svg'
import { ReactComponent as InvertColorsIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/Invert-colors.svg'
import { ReactComponent as ItalicIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/italic.svg'
import { ReactComponent as KeyIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/key.svg'
import { ReactComponent as KeysIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/keys.svg'
import { ReactComponent as LayersIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/layers.svg'
import { ReactComponent as LayoutIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/layout.svg'
import { ReactComponent as LifeBuoyIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/life-buoy.svg'
import { ReactComponent as Link2Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/link-2.svg'
import { ReactComponent as LinkIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/link.svg'
import { ReactComponent as LinkedinIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/linkedin.svg'
import { ReactComponent as ListIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/list.svg'
import { ReactComponent as LoaderIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/loader.svg'
import { ReactComponent as LockIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/lock.svg'
import { ReactComponent as LogInIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/log-in.svg'
import { ReactComponent as LogOutIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/log-out.svg'
import { ReactComponent as MailIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/mail.svg'
import { ReactComponent as MapPinIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/map-pin.svg'
import { ReactComponent as MapIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/map.svg'
import { ReactComponent as Maximize2Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/maximize-2.svg'
import { ReactComponent as MehIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/meh.svg'
import { ReactComponent as MenuIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/menu.svg'
import { ReactComponent as MessageCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/message-circle.svg'
import { ReactComponent as MessageSquareIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/message-square.svg'
import { ReactComponent as MessageSquaresIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/message-squares.svg'
import { ReactComponent as MicOffIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/mic-off.svg'
import { ReactComponent as MicIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/mic.svg'
import { ReactComponent as Minimize2Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/minimize-2.svg'
import { ReactComponent as MinimizeIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/minimize.svg'
import { ReactComponent as MinusCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/minus-circle.svg'
import { ReactComponent as MinusSquareIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/minus-square.svg'
import { ReactComponent as MinusIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/minus.svg'
import { ReactComponent as MonitorIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/monitor.svg'
import { ReactComponent as MoonIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/moon.svg'
import { ReactComponent as MoreHorizontalIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/more-horizontal.svg'
import { ReactComponent as MoreVerticalIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/more-vertical.svg'
import { ReactComponent as MousePointerIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/mouse-pointer.svg'
import { ReactComponent as MoveIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/move.svg'
import { ReactComponent as MusicIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/music.svg'
import { ReactComponent as Navigation2Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/navigation-2.svg'
import { ReactComponent as NavigationIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/navigation.svg'
import { ReactComponent as NotesAddIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/notes-add.svg'
import { ReactComponent as NotesCheckIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/notes-check.svg'
import { ReactComponent as NotesChecklistFlipIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/notes-checklist-flip.svg'
import { ReactComponent as NotesClockIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/notes-clock.svg'
import { ReactComponent as NotesPaperApproveIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/notes-paper-approve.svg'
import { ReactComponent as NotesPaperTextIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/notes-paper-text.svg'
import { ReactComponent as NotesQuestionIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/notes-question.svg'
import { ReactComponent as NotesRemoveIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/notes-remove.svg'
import { ReactComponent as OctagonIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/octagon.svg'
import { ReactComponent as OfficeStampDocumentIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/office-stamp-document.svg'
import { ReactComponent as OminichannelIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/ominichannel.svg'
import { ReactComponent as PackageIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/package.svg'
import { ReactComponent as PaperclipIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/paperclip.svg'
import { ReactComponent as PauseCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/pause-circle.svg'
import { ReactComponent as PauseIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/pause.svg'
import { ReactComponent as PenToolIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/pen-tool.svg'
import { ReactComponent as PhoneIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/phone.svg'
import { ReactComponent as PieChartIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/pie-chart.svg'
import { ReactComponent as PlacaIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/placa.svg'
import { ReactComponent as PlaceholderIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/placeholder.svg'
import { ReactComponent as PlateNewIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/plate-new.svg'
import { ReactComponent as PlayCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/play-circle.svg'
import { ReactComponent as PlayIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/play.svg'
import { ReactComponent as PlusCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/plus-circle.svg'
import { ReactComponent as PlusSquareIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/plus-square.svg'
import { ReactComponent as PlusIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/plus.svg'
import { ReactComponent as PocketIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/pocket.svg'
import { ReactComponent as PowerIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/power.svg'
import { ReactComponent as PrimeiraMaoIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/primeiramao.svg'
import { ReactComponent as PrinterIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/printer.svg'
import { ReactComponent as QuestionIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/question.svg'
import { ReactComponent as R$Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/R$.svg'
import { ReactComponent as RadioIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/radio.svg'
import { ReactComponent as ReceiptRegisterIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/receipt-register.svg'
import { ReactComponent as RefreshCcwIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/refresh-ccw.svg'
import { ReactComponent as RefreshCw1Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/refresh-cw-1.svg'
import { ReactComponent as RefreshCwIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/refresh-cw.svg'
import { ReactComponent as RegisteredIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/Registered.svg'
import { ReactComponent as RepeatIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/repeat.svg'
import { ReactComponent as RewindTimeIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/rewind-time.svg'
import { ReactComponent as RewindIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/rewind.svg'
import { ReactComponent as RoadFillIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/road-fill.svg'
import { ReactComponent as RotateCcwIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/rotate-ccw.svg'
import { ReactComponent as RotateCwIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/rotate-cw.svg'
import { ReactComponent as RssIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/rss.svg'
import { ReactComponent as SagaSeminovosIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/sagaseminovos.svg'
import { ReactComponent as SaveIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/save.svg'
import { ReactComponent as ScissorsIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/scissors.svg'
import { ReactComponent as ScooterSportIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/scooter-sport.svg'
import { ReactComponent as SearchCarIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/search car.svg'
import { ReactComponent as SearchIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/search.svg'
import { ReactComponent as SendIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/send.svg'
import { ReactComponent as ServerIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/server.svg'
import { ReactComponent as SettingsIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/settings.svg'
import { ReactComponent as Share2Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/share-2.svg'
import { ReactComponent as ShareIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/share.svg'
import { ReactComponent as ShieldOffIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/shield-off.svg'
import { ReactComponent as ShieldIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/shield.svg'
import { ReactComponent as ShoppingBagIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/shopping-bag.svg'
import { ReactComponent as ShoppingCartCashIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/shopping-cart-cash.svg'
import { ReactComponent as ShoppingCartIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/shopping-cart.svg'
import { ReactComponent as ShuffleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/shuffle.svg'
import { ReactComponent as SidebarIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/sidebar.svg'
import { ReactComponent as SkipBackIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/skip-back.svg'
import { ReactComponent as SkipForwardIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/skip-forward.svg'
import { ReactComponent as SlashIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/slash.svg'
import { ReactComponent as SlidersIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/sliders.svg'
import { ReactComponent as SmallKilometerIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/Small-kilometer.svg'
import { ReactComponent as SmartphoneIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/smartphone.svg'
import { ReactComponent as SmileIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/smile.svg'
import { ReactComponent as SpeakerIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/speaker.svg'
import { ReactComponent as SquareIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/square.svg'
import { ReactComponent as StarIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/star.svg'
import { ReactComponent as StarFullIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/starFull.svg'
import { ReactComponent as SteeringWheelIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/steering-wheel.svg'
import { ReactComponent as StopCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/stop-circle.svg'
import { ReactComponent as StoreIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/store.svg'
import { ReactComponent as SumIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/sum.svg'
import { ReactComponent as SunIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/sun.svg'
import { ReactComponent as SunriseIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/sunrise.svg'
import { ReactComponent as SunsetIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/sunset.svg'
import { ReactComponent as SymbolMoneyIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/SymbolMoney.svg'
import { ReactComponent as TabletIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/tablet.svg'
import { ReactComponent as TagDollar1Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/tag-dollar-1.svg'
import { ReactComponent as TagDollarIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/tag-dollar.svg'
import { ReactComponent as TagIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/tag.svg'
import { ReactComponent as TargetIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/target.svg'
import { ReactComponent as TaskApprovedIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/task-approved.svg'
import { ReactComponent as TerminalIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/terminal.svg'
import { ReactComponent as ThermometerIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/thermometer.svg'
import { ReactComponent as ThumbsDownIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/thumbs-down.svg'
import { ReactComponent as ThumbsUpIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/thumbs-up.svg'
import { ReactComponent as TiktokIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/tiktok.svg'
import { ReactComponent as ToggleLeftIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/toggle-left.svg'
import { ReactComponent as ToggleRightIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/toggle-right.svg'
import { ReactComponent as ToolIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/tool.svg'
import { ReactComponent as TransmissionIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/transmission.svg'
import { ReactComponent as Trash2Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/trash-2.svg'
import { ReactComponent as TrashIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/trash.svg'
import { ReactComponent as TrelloIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/trello.svg'
import { ReactComponent as TrendingDownIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/trending-down.svg'
import { ReactComponent as TrendingUpIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/trending-up.svg'
import { ReactComponent as TrocaIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/Troca.svg'
import { ReactComponent as TruckEmpty1Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/truck-empty-1.svg'
import { ReactComponent as TruckIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/truck.svg'
import { ReactComponent as TtvIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/tv.svg'
import { ReactComponent as TwitchIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/twitch.svg'
import { ReactComponent as TwitterIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/twitter.svg'
import { ReactComponent as TypeIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/type.svg'
import { ReactComponent as UmbrellaIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/umbrella.svg'
import { ReactComponent as UnderlineIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/underline.svg'
import { ReactComponent as UnlockIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/unlock.svg'
import { ReactComponent as UploadCloudIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/upload-cloud.svg'
import { ReactComponent as UploadIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/upload.svg'
import { ReactComponent as UserCheckIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/user-check.svg'
import { ReactComponent as UserCircleIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/user-circle.svg'
import { ReactComponent as UserMinusIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/user-minus.svg'
import { ReactComponent as UserPlusIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/user-plus.svg'
import { ReactComponent as UserCancelIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/user-x.svg'
import { ReactComponent as UserIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/user.svg'
import { ReactComponent as UsersIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/users.svg'
import { ReactComponent as VerifiedIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/verified.svg'
import { ReactComponent as VersionsIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/Versions.svg'
import { ReactComponent as VideoOffIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/video-off.svg'
import { ReactComponent as VideoIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/video.svg'
import { ReactComponent as VoicemailIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/voicemail.svg'
import { ReactComponent as Volume1Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/volume-1.svg'
import { ReactComponent as Volume2Icon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/volume-2.svg'
import { ReactComponent as VolumeMutedIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/volume-x.svg'
import { ReactComponent as VolumeIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/volume.svg'
import { ReactComponent as WhatsappIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/whatsapp.svg'
import { ReactComponent as WifiOffIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/wifi-off.svg'
import { ReactComponent as WifiIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/wifi.svg'
import { ReactComponent as WindIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/wind.svg'
import { ReactComponent as YesNoIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/yes-no.svg'
import { ReactComponent as YoutubeIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/youtube.svg'
import { ReactComponent as ZapOffIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/zap-off.svg'
import { ReactComponent as ZapIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/zap.svg'
import { ReactComponent as ZoomInIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/zoom-in.svg'
import { ReactComponent as ZoomOutIcon } from '@sagacarmi/seminovos-design-system/core/tokens/icons/svg-saga/icons/zoom-out.svg'

export type IconType =
  | 'AllRoundIcon'
  | 'ActivityIcon'
  | 'AirplayIcon'
  | 'AlertCircleIcon'
  | 'AlertOctagonIcon'
  | 'AlertTriangleIcon'
  | 'AlignCenterIcon'
  | 'AlignJustifyIcon'
  | 'AlignLeftIcon'
  | 'AlignRightIcon'
  | 'AnchorIcon'
  | 'ApertureIcon'
  | 'AppleFullIcon'
  | 'AppleIcon'
  | 'ArchiveIcon'
  | 'ArrowDownCircleIcon'
  | 'ArrowDownLeftIcon'
  | 'ArrowDownRightIcon'
  | 'ArrowDownIcon'
  | 'ArrowFilledDownIcon'
  | 'ArrowFilledUpIcon'
  | 'ArrowLeftCircleIcon'
  | 'ArrowLeftIcon'
  | 'ArrowRightCircleIcon'
  | 'ArrowRightIcon'
  | 'ArrowUpCircleIcon'
  | 'ArrowUpDownIcon'
  | 'ArrowUpLeftIcon'
  | 'ArrowUpRightIcon'
  | 'ArrowUpIcon'
  | 'AtSignIcon'
  | 'AwardIcon'
  | 'BarChartIcon'
  | 'BarChart2Icon'
  | 'BarCodeSearchIcon'
  | 'BatteryChargingIcon'
  | 'BatteryIcon'
  | 'BellOffIcon'
  | 'BellIcon'
  | 'BlankIcon'
  | 'BluetoothIcon'
  | 'BoldIcon'
  | 'BookmarkIcon'
  | 'BookOpenIcon'
  | 'BookIcon'
  | 'BoxIcon'
  | 'BriefcaseIcon'
  | 'CalendarCheckIcon'
  | 'CalendarCheck1Icon'
  | 'CalendarClockIcon'
  | 'CalendarIcon'
  | 'CameraOffIcon'
  | 'CameraIcon'
  | 'CardsIcon'
  | 'CarPerfectIcon'
  | 'CarRepairBottomIcon'
  | 'CarRepairChecklistIcon'
  | 'CarRetroIcon'
  | 'CarSideIcon'
  | 'CashPaymentBillsIcon'
  | 'CastIcon'
  | 'CheckCircle1Icon'
  | 'CheckCircleIcon'
  | 'CheckSquareIcon'
  | 'CheckIcon'
  | 'ChevronDownIcon'
  | 'ChevronLeftIcon'
  | 'ChevronRightIcon'
  | 'ChevronsDownIcon'
  | 'ChevronsLeftIcon'
  | 'ChevronsRightIcon'
  | 'ChevronsUpIcon'
  | 'ChevronUpIcon'
  | 'ChromeIcon'
  | 'CircleIcon'
  | 'ClipboardIcon'
  | 'ClockIcon'
  | 'CloudRainIcon'
  | 'CloudSnowIcon'
  | 'CloudIcon'
  | 'CodepenIcon'
  | 'CodesandboxIcon'
  | 'CodeIcon'
  | 'CoffeeIcon'
  | 'ColumnsIcon'
  | 'CommandIcon'
  | 'CompassIcon'
  | 'ComponentIcon'
  | 'ComputerIcon'
  | 'CopyIcon'
  | 'CornerDownLeftIcon'
  | 'CornerDownRightIcon'
  | 'CornerLeftDownIcon'
  | 'CornerLeftUpIcon'
  | 'CornerRightDownIcon'
  | 'CornerRightUpIcon'
  | 'CornerUpLeftIcon'
  | 'CornerUpRightIcon'
  | 'CpuIcon'
  | 'CreditCardIcon'
  | 'CropIcon'
  | 'CrosshairIcon'
  | 'DashboardIcon'
  | 'DatabaseIcon'
  | 'DeleteIcon'
  | 'DeliveryTruckIcon'
  | 'DiscountIcon'
  | 'Discount1Icon'
  | 'DiscountBubbleIcon'
  | 'DiscIcon'
  | 'DivideCircleIcon'
  | 'DivideSquareIcon'
  | 'DivideIcon'
  | 'DollarSignIcon'
  | 'DownloadCloudIcon'
  | 'DownloadIcon'
  | 'DribbbleIcon'
  | 'DropletIcon'
  | 'EditIcon'
  | 'Edit2Icon'
  | 'Edit3Icon'
  | 'EngineIcon'
  | 'Engine1Icon'
  | 'ExternalLinkIcon'
  | 'EyeOffIcon'
  | 'EyeIcon'
  | 'FacebookIcon'
  | 'Facebook1Icon'
  | 'FastForwardIcon'
  | 'FeatherIcon'
  | 'FigmaIcon'
  | 'FileMinusIcon'
  | 'FilePlusIcon'
  | 'FileIcon'
  | 'FileTextIcon'
  | 'FilmIcon'
  | 'FilterIcon'
  | 'FilterSettingsIcon'
  | 'FlagIcon'
  | 'FolderMinusIcon'
  | 'FolderPlusIcon'
  | 'FolderIcon'
  | 'FrownIcon'
  | 'FuelIcon'
  | 'GiftIcon'
  | 'Gift1Icon'
  | 'GlobeIcon'
  | 'Google2Icon'
  | 'GoogleIcon'
  | 'GridIcon'
  | 'HandshakeIcon'
  | 'HardDriveIcon'
  | 'HashIcon'
  | 'HeadphonesIcon'
  | 'HeartFullIcon'
  | 'HeartIcon'
  | 'HelpCircleIcon'
  | 'HexagonIcon'
  | 'HomeIcon'
  | 'ImageIcon'
  | 'InboxIcon'
  | 'InfoIcon'
  | 'InstagramIcon'
  | 'InvertColorsIcon'
  | 'ItalicIcon'
  | 'KeyIcon'
  | 'KeysIcon'
  | 'LayersIcon'
  | 'LayoutIcon'
  | 'LifeBuoyIcon'
  | 'LinkIcon'
  | 'Link2Icon'
  | 'LinkedinIcon'
  | 'ListIcon'
  | 'LoaderIcon'
  | 'LockIcon'
  | 'LogInIcon'
  | 'LogOutIcon'
  | 'MailIcon'
  | 'MapPinIcon'
  | 'MapIcon'
  | 'Maximize2Icon'
  | 'MehIcon'
  | 'MenuIcon'
  | 'MessageCircleIcon'
  | 'MessageSquareIcon'
  | 'MessageSquaresIcon'
  | 'MicOffIcon'
  | 'MicIcon'
  | 'Minimize2Icon'
  | 'MinimizeIcon'
  | 'MinusCircleIcon'
  | 'MinusSquareIcon'
  | 'MinusIcon'
  | 'MonitorIcon'
  | 'MoonIcon'
  | 'MoreHorizontalIcon'
  | 'MoreVerticalIcon'
  | 'MousePointerIcon'
  | 'MoveIcon'
  | 'MusicIcon'
  | 'Navigation2Icon'
  | 'NavigationIcon'
  | 'NotesAddIcon'
  | 'NotesChecklistFlipIcon'
  | 'NotesCheckIcon'
  | 'NotesClockIcon'
  | 'NotesPaperApproveIcon'
  | 'NotesPaperTextIcon'
  | 'NotesQuestionIcon'
  | 'NotesRemoveIcon'
  | 'OctagonIcon'
  | 'OfficeStampDocumentIcon'
  | 'OminichannelIcon'
  | 'PackageIcon'
  | 'PaperclipIcon'
  | 'PauseCircleIcon'
  | 'PauseIcon'
  | 'PenToolIcon'
  | 'PhoneIcon'
  | 'PieChartIcon'
  | 'PlacaIcon'
  | 'PlaceholderIcon'
  | 'PlayCircleIcon'
  | 'PlayIcon'
  | 'PlateNewIcon'
  | 'PlusCircleIcon'
  | 'PlusSquareIcon'
  | 'PlusIcon'
  | 'PocketIcon'
  | 'PowerIcon'
  | 'PrimeiraMaoIcon'
  | 'PrinterIcon'
  | 'QuestionIcon'
  | 'R$Icon'
  | 'RadioIcon'
  | 'ReceiptRegisterIcon'
  | 'RefreshCcwIcon'
  | 'RefreshCw1Icon'
  | 'RefreshCwIcon'
  | 'RegisteredIcon'
  | 'RepeatIcon'
  | 'RewindIcon'
  | 'RewindTimeIcon'
  | 'RoadFillIcon'
  | 'RotateCcwIcon'
  | 'RotateCwIcon'
  | 'RssIcon'
  | 'SagaSeminovosIcon'
  | 'SaveIcon'
  | 'ScissorsIcon'
  | 'ScooterSportIcon'
  | 'SearchCarIcon'
  | 'SearchIcon'
  | 'SendIcon'
  | 'ServerIcon'
  | 'SettingsIcon'
  | 'Share2Icon'
  | 'ShareIcon'
  | 'ShieldOffIcon'
  | 'ShieldIcon'
  | 'ShoppingBagIcon'
  | 'ShoppingCartCashIcon'
  | 'ShoppingCartIcon'
  | 'ShuffleIcon'
  | 'SidebarIcon'
  | 'SkipBackIcon'
  | 'SkipForwardIcon'
  | 'SlashIcon'
  | 'SlidersIcon'
  | 'SmallKilometerIcon'
  | 'SmartphoneIcon'
  | 'SmileIcon'
  | 'SpeakerIcon'
  | 'SquareIcon'
  | 'StarFullIcon'
  | 'StarIcon'
  | 'SteeringWheelIcon'
  | 'StopCircleIcon'
  | 'StoreIcon'
  | 'SumIcon'
  | 'SunriseIcon'
  | 'SunsetIcon'
  | 'SunIcon'
  | 'SymbolMoneyIcon'
  | 'TabletIcon'
  | 'TagDollarIcon'
  | 'TagDollar1Icon'
  | 'TagIcon'
  | 'TargetIcon'
  | 'TaskApprovedIcon'
  | 'TerminalIcon'
  | 'ThermometerIcon'
  | 'ThumbsDownIcon'
  | 'ThumbsUpIcon'
  | 'TiktokIcon'
  | 'ToggleLeftIcon'
  | 'ToggleRightIcon'
  | 'ToolIcon'
  | 'TransmissionIcon'
  | 'TrashIcon'
  | 'Trash2Icon'
  | 'TrelloIcon'
  | 'TrendingDownIcon'
  | 'TrendingUpIcon'
  | 'TrocaIcon'
  | 'TruckEmpty1Icon'
  | 'TruckIcon'
  | 'TtvIcon'
  | 'TwitchIcon'
  | 'TwitterIcon'
  | 'TypeIcon'
  | 'UmbrellaIcon'
  | 'UnderlineIcon'
  | 'UnlockIcon'
  | 'UploadCloudIcon'
  | 'UploadIcon'
  | 'UserCheckIcon'
  | 'UserMinusIcon'
  | 'UserPlusIcon'
  | 'UsersIcon'
  | 'UserIcon'
  | 'UserCancelIcon'
  | 'UserCircleIcon'
  | 'VerifiedIcon'
  | 'VersionsIcon'
  | 'VideoOffIcon'
  | 'VideoIcon'
  | 'VoicemailIcon'
  | 'Volume1Icon'
  | 'Volume2Icon'
  | 'VolumeIcon'
  | 'VolumeMutedIcon'
  | 'WhatsappIcon'
  | 'WifiOffIcon'
  | 'WifiIcon'
  | 'WindIcon'
  | 'CloseCircleIcon'
  | 'CloseOctagonIcon'
  | 'CloseSquareIcon'
  | 'CloseIcon'
  | 'YesNoIcon'
  | 'YoutubeIcon'
  | 'ZapOffIcon'
  | 'ZapIcon'
  | 'ZoomInIcon'
  | 'ZoomOutIcon'

export const getIcon = (type: IconType) => {
  const icons = {
    AllRoundIcon: AllRoundIcon,
    ActivityIcon: ActivityIcon,
    AirplayIcon: AirplayIcon,
    AlertCircleIcon: AlertCircleIcon,
    AlertOctagonIcon: AlertOctagonIcon,
    AlertTriangleIcon: AlertTriangleIcon,
    AlignCenterIcon: AlignCenterIcon,
    AlignJustifyIcon: AlignJustifyIcon,
    AlignLeftIcon: AlignLeftIcon,
    AlignRightIcon: AlignRightIcon,
    AnchorIcon: AnchorIcon,
    ApertureIcon: ApertureIcon,
    AppleFullIcon: AppleFullIcon,
    AppleIcon: AppleIcon,
    ArchiveIcon: ArchiveIcon,
    ArrowDownCircleIcon: ArrowDownCircleIcon,
    ArrowDownLeftIcon: ArrowDownLeftIcon,
    ArrowDownRightIcon: ArrowDownRightIcon,
    ArrowDownIcon: ArrowDownIcon,
    ArrowFilledDownIcon: ArrowFilledDownIcon,
    ArrowFilledUpIcon: ArrowFilledUpIcon,
    ArrowLeftCircleIcon: ArrowLeftCircleIcon,
    ArrowLeftIcon: ArrowLeftIcon,
    ArrowRightCircleIcon: ArrowRightCircleIcon,
    ArrowRightIcon: ArrowRightIcon,
    ArrowUpCircleIcon: ArrowUpCircleIcon,
    ArrowUpDownIcon: ArrowUpDownIcon,
    ArrowUpLeftIcon: ArrowUpLeftIcon,
    ArrowUpRightIcon: ArrowUpRightIcon,
    ArrowUpIcon: ArrowUpIcon,
    AtSignIcon: AtSignIcon,
    AwardIcon: AwardIcon,
    BarChartIcon: BarChartIcon,
    BarChart2Icon: BarChart2Icon,
    BarCodeSearchIcon: BarCodeSearchIcon,
    BatteryChargingIcon: BatteryChargingIcon,
    BatteryIcon: BatteryIcon,
    BellOffIcon: BellOffIcon,
    BellIcon: BellIcon,
    BlankIcon: BlankIcon,
    BluetoothIcon: BluetoothIcon,
    BoldIcon: BoldIcon,
    BookmarkIcon: BookmarkIcon,
    BookOpenIcon: BookOpenIcon,
    BookIcon: BookIcon,
    BoxIcon: BoxIcon,
    BriefcaseIcon: BriefcaseIcon,
    CalendarCheckIcon: CalendarCheckIcon,
    CalendarCheck1Icon: CalendarCheck1Icon,
    CalendarClockIcon: CalendarClockIcon,
    CalendarIcon: CalendarIcon,
    CameraOffIcon: CameraOffIcon,
    CameraIcon: CameraIcon,
    CardsIcon: CardsIcon,
    CarPerfectIcon: CarPerfectIcon,
    CarRepairBottomIcon: CarRepairBottomIcon,
    CarRepairChecklistIcon: CarRepairChecklistIcon,
    CarRetroIcon: CarRetroIcon,
    CarSideIcon: CarSideIcon,
    CashPaymentBillsIcon: CashPaymentBillsIcon,
    CastIcon: CastIcon,
    CheckCircle1Icon: CheckCircle1Icon,
    CheckCircleIcon: CheckCircleIcon,
    CheckSquareIcon: CheckSquareIcon,
    CheckIcon: CheckIcon,
    ChevronDownIcon: ChevronDownIcon,
    ChevronLeftIcon: ChevronLeftIcon,
    ChevronRightIcon: ChevronRightIcon,
    ChevronsDownIcon: ChevronsDownIcon,
    ChevronsLeftIcon: ChevronsLeftIcon,
    ChevronsRightIcon: ChevronsRightIcon,
    ChevronsUpIcon: ChevronsUpIcon,
    ChevronUpIcon: ChevronUpIcon,
    ChromeIcon: ChromeIcon,
    CircleIcon: CircleIcon,
    ClipboardIcon: ClipboardIcon,
    ClockIcon: ClockIcon,
    CloudRainIcon: CloudRainIcon,
    CloudSnowIcon: CloudSnowIcon,
    CloudIcon: CloudIcon,
    CodepenIcon: CodepenIcon,
    CodesandboxIcon: CodesandboxIcon,
    CodeIcon: CodeIcon,
    CoffeeIcon: CoffeeIcon,
    ColumnsIcon: ColumnsIcon,
    CommandIcon: CommandIcon,
    CompassIcon: CompassIcon,
    ComponentIcon: ComponentIcon,
    ComputerIcon: ComputerIcon,
    CopyIcon: CopyIcon,
    CornerDownLeftIcon: CornerDownLeftIcon,
    CornerDownRightIcon: CornerDownRightIcon,
    CornerLeftDownIcon: CornerLeftDownIcon,
    CornerLeftUpIcon: CornerLeftUpIcon,
    CornerRightDownIcon: CornerRightDownIcon,
    CornerRightUpIcon: CornerRightUpIcon,
    CornerUpLeftIcon: CornerUpLeftIcon,
    CornerUpRightIcon: CornerUpRightIcon,
    CpuIcon: CpuIcon,
    CreditCardIcon: CreditCardIcon,
    CropIcon: CropIcon,
    CrosshairIcon: CrosshairIcon,
    DashboardIcon: DashboardIcon,
    DatabaseIcon: DatabaseIcon,
    DeleteIcon: DeleteIcon,
    DeliveryTruckIcon: DeliveryTruckIcon,
    DiscountIcon: DiscountIcon,
    Discount1Icon: Discount1Icon,
    DiscountBubbleIcon: DiscountBubbleIcon,
    DiscIcon: DiscIcon,
    DivideCircleIcon: DivideCircleIcon,
    DivideSquareIcon: DivideSquareIcon,
    DivideIcon: DivideIcon,
    DollarSignIcon: DollarSignIcon,
    DownloadCloudIcon: DownloadCloudIcon,
    DownloadIcon: DownloadIcon,
    DribbbleIcon: DribbbleIcon,
    DropletIcon: DropletIcon,
    EditIcon: EditIcon,
    Edit2Icon: Edit2Icon,
    Edit3Icon: Edit3Icon,
    EngineIcon: EngineIcon,
    Engine1Icon: Engine1Icon,
    ExternalLinkIcon: ExternalLinkIcon,
    EyeOffIcon: EyeOffIcon,
    EyeIcon: EyeIcon,
    FacebookIcon: FacebookIcon,
    Facebook1Icon: Facebook1Icon,
    FastForwardIcon: FastForwardIcon,
    FeatherIcon: FeatherIcon,
    FigmaIcon: FigmaIcon,
    FileMinusIcon: FileMinusIcon,
    FilePlusIcon: FilePlusIcon,
    FileIcon: FileIcon,
    FileTextIcon: FileTextIcon,
    FilmIcon: FilmIcon,
    FilterIcon: FilterIcon,
    FilterSettingsIcon: FilterSettingsIcon,
    FlagIcon: FlagIcon,
    FolderMinusIcon: FolderMinusIcon,
    FolderPlusIcon: FolderPlusIcon,
    FolderIcon: FolderIcon,
    FrownIcon: FrownIcon,
    FuelIcon: FuelIcon,
    GiftIcon: GiftIcon,
    Gift1Icon: Gift1Icon,
    GlobeIcon: GlobeIcon,
    Google2Icon: Google2Icon,
    GoogleIcon: GoogleIcon,
    GridIcon: GridIcon,
    HandshakeIcon: HandshakeIcon,
    HardDriveIcon: HardDriveIcon,
    HashIcon: HashIcon,
    HeadphonesIcon: HeadphonesIcon,
    HeartFullIcon: HeartFullIcon,
    HeartIcon: HeartIcon,
    HelpCircleIcon: HelpCircleIcon,
    HexagonIcon: HexagonIcon,
    HomeIcon: HomeIcon,
    ImageIcon: ImageIcon,
    InboxIcon: InboxIcon,
    InfoIcon: InfoIcon,
    InstagramIcon: InstagramIcon,
    InvertColorsIcon: InvertColorsIcon,
    ItalicIcon: ItalicIcon,
    KeyIcon: KeyIcon,
    KeysIcon: KeysIcon,
    LayersIcon: LayersIcon,
    LayoutIcon: LayoutIcon,
    LifeBuoyIcon: LifeBuoyIcon,
    LinkIcon: LinkIcon,
    Link2Icon: Link2Icon,
    LinkedinIcon: LinkedinIcon,
    ListIcon: ListIcon,
    LoaderIcon: LoaderIcon,
    LockIcon: LockIcon,
    LogInIcon: LogInIcon,
    LogOutIcon: LogOutIcon,
    MailIcon: MailIcon,
    MapPinIcon: MapPinIcon,
    MapIcon: MapIcon,
    Maximize2Icon: Maximize2Icon,
    MehIcon: MehIcon,
    MenuIcon: MenuIcon,
    MessageCircleIcon: MessageCircleIcon,
    MessageSquareIcon: MessageSquareIcon,
    MessageSquaresIcon: MessageSquaresIcon,
    MicOffIcon: MicOffIcon,
    MicIcon: MicIcon,
    Minimize2Icon: Minimize2Icon,
    MinimizeIcon: MinimizeIcon,
    MinusCircleIcon: MinusCircleIcon,
    MinusSquareIcon: MinusSquareIcon,
    MinusIcon: MinusIcon,
    MonitorIcon: MonitorIcon,
    MoonIcon: MoonIcon,
    MoreHorizontalIcon: MoreHorizontalIcon,
    MoreVerticalIcon: MoreVerticalIcon,
    MousePointerIcon: MousePointerIcon,
    MoveIcon: MoveIcon,
    MusicIcon: MusicIcon,
    Navigation2Icon: Navigation2Icon,
    NavigationIcon: NavigationIcon,
    NotesAddIcon: NotesAddIcon,
    NotesChecklistFlipIcon: NotesChecklistFlipIcon,
    NotesCheckIcon: NotesCheckIcon,
    NotesClockIcon: NotesClockIcon,
    NotesPaperApproveIcon: NotesPaperApproveIcon,
    NotesPaperTextIcon: NotesPaperTextIcon,
    NotesQuestionIcon: NotesQuestionIcon,
    NotesRemoveIcon: NotesRemoveIcon,
    OctagonIcon: OctagonIcon,
    OfficeStampDocumentIcon: OfficeStampDocumentIcon,
    OminichannelIcon: OminichannelIcon,
    PackageIcon: PackageIcon,
    PaperclipIcon: PaperclipIcon,
    PauseCircleIcon: PauseCircleIcon,
    PauseIcon: PauseIcon,
    PenToolIcon: PenToolIcon,
    PhoneIcon: PhoneIcon,
    PieChartIcon: PieChartIcon,
    PlacaIcon: PlacaIcon,
    PlaceholderIcon: PlaceholderIcon,
    PlayCircleIcon: PlayCircleIcon,
    PlayIcon: PlayIcon,
    PlateNewIcon: PlateNewIcon,
    PlusCircleIcon: PlusCircleIcon,
    PlusSquareIcon: PlusSquareIcon,
    PlusIcon: PlusIcon,
    PocketIcon: PocketIcon,
    PowerIcon: PowerIcon,
    PrimeiraMaoIcon: PrimeiraMaoIcon,
    PrinterIcon: PrinterIcon,
    QuestionIcon: QuestionIcon,
    R$Icon: R$Icon,
    RadioIcon: RadioIcon,
    ReceiptRegisterIcon: ReceiptRegisterIcon,
    RefreshCcwIcon: RefreshCcwIcon,
    RefreshCw1Icon: RefreshCw1Icon,
    RefreshCwIcon: RefreshCwIcon,
    RegisteredIcon: RegisteredIcon,
    RepeatIcon: RepeatIcon,
    RewindIcon: RewindIcon,
    RewindTimeIcon: RewindTimeIcon,
    RoadFillIcon: RoadFillIcon,
    RotateCcwIcon: RotateCcwIcon,
    RotateCwIcon: RotateCwIcon,
    RssIcon: RssIcon,
    SagaSeminovosIcon: SagaSeminovosIcon,
    SaveIcon: SaveIcon,
    ScissorsIcon: ScissorsIcon,
    ScooterSportIcon: ScooterSportIcon,
    SearchCarIcon: SearchCarIcon,
    SearchIcon: SearchIcon,
    SendIcon: SendIcon,
    ServerIcon: ServerIcon,
    SettingsIcon: SettingsIcon,
    Share2Icon: Share2Icon,
    ShareIcon: ShareIcon,
    ShieldOffIcon: ShieldOffIcon,
    ShieldIcon: ShieldIcon,
    ShoppingBagIcon: ShoppingBagIcon,
    ShoppingCartCashIcon: ShoppingCartCashIcon,
    ShoppingCartIcon: ShoppingCartIcon,
    ShuffleIcon: ShuffleIcon,
    SidebarIcon: SidebarIcon,
    SkipBackIcon: SkipBackIcon,
    SkipForwardIcon: SkipForwardIcon,
    SlashIcon: SlashIcon,
    SlidersIcon: SlidersIcon,
    SmallKilometerIcon: SmallKilometerIcon,
    SmartphoneIcon: SmartphoneIcon,
    SmileIcon: SmileIcon,
    SpeakerIcon: SpeakerIcon,
    SquareIcon: SquareIcon,
    StarFullIcon: StarFullIcon,
    StarIcon: StarIcon,
    SteeringWheelIcon: SteeringWheelIcon,
    StopCircleIcon: StopCircleIcon,
    StoreIcon: StoreIcon,
    SumIcon: SumIcon,
    SunriseIcon: SunriseIcon,
    SunsetIcon: SunsetIcon,
    SunIcon: SunIcon,
    SymbolMoneyIcon: SymbolMoneyIcon,
    TabletIcon: TabletIcon,
    TagDollarIcon: TagDollarIcon,
    TagDollar1Icon: TagDollar1Icon,
    TagIcon: TagIcon,
    TargetIcon: TargetIcon,
    TaskApprovedIcon: TaskApprovedIcon,
    TerminalIcon: TerminalIcon,
    ThermometerIcon: ThermometerIcon,
    ThumbsDownIcon: ThumbsDownIcon,
    ThumbsUpIcon: ThumbsUpIcon,
    TiktokIcon: TiktokIcon,
    ToggleLeftIcon: ToggleLeftIcon,
    ToggleRightIcon: ToggleRightIcon,
    ToolIcon: ToolIcon,
    TransmissionIcon: TransmissionIcon,
    TrashIcon: TrashIcon,
    Trash2Icon: Trash2Icon,
    TrelloIcon: TrelloIcon,
    TrendingDownIcon: TrendingDownIcon,
    TrendingUpIcon: TrendingUpIcon,
    TrocaIcon: TrocaIcon,
    TruckEmpty1Icon: TruckEmpty1Icon,
    TruckIcon: TruckIcon,
    TtvIcon: TtvIcon,
    TwitchIcon: TwitchIcon,
    TwitterIcon: TwitterIcon,
    TypeIcon: TypeIcon,
    UmbrellaIcon: UmbrellaIcon,
    UnderlineIcon: UnderlineIcon,
    UnlockIcon: UnlockIcon,
    UploadCloudIcon: UploadCloudIcon,
    UploadIcon: UploadIcon,
    UserCheckIcon: UserCheckIcon,
    UserMinusIcon: UserMinusIcon,
    UserPlusIcon: UserPlusIcon,
    UsersIcon: UsersIcon,
    UserIcon: UserIcon,
    UserCancelIcon: UserCancelIcon,
    UserCircleIcon: UserCircleIcon,
    VerifiedIcon: VerifiedIcon,
    VersionsIcon: VersionsIcon,
    VideoOffIcon: VideoOffIcon,
    VideoIcon: VideoIcon,
    VoicemailIcon: VoicemailIcon,
    Volume1Icon: Volume1Icon,
    Volume2Icon: Volume2Icon,
    VolumeIcon: VolumeIcon,
    VolumeMutedIcon: VolumeMutedIcon,
    WhatsappIcon: WhatsappIcon,
    WifiOffIcon: WifiOffIcon,
    WifiIcon: WifiIcon,
    WindIcon: WindIcon,
    CloseCircleIcon: CloseCircleIcon,
    CloseOctagonIcon: CloseOctagonIcon,
    CloseSquareIcon: CloseSquareIcon,
    CloseIcon: CloseIcon,
    YesNoIcon: YesNoIcon,
    YoutubeIcon: YoutubeIcon,
    ZapOffIcon: ZapOffIcon,
    ZapIcon: ZapIcon,
    ZoomInIcon: ZoomInIcon,
    ZoomOutIcon: ZoomOutIcon
  }

  return icons[type]
}
