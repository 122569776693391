/* eslint-disable @typescript-eslint/no-explicit-any */
import request, { ResponseInterceptor } from 'helpers/request'

export interface EditDataRequest {
  address: string
  birthDate: string
  cityId: number
  complement: string
  cpf: number
  gender: string
  imageId: number
  mobile: string
  neighborhood: string
  number: string
  phone: string
  zipcode: string
}

export type InternalUserPermissions = {
  id: number
  name: string
  description: string
}

export enum PermissionsType {
  USUARIOS = 1,
  PERSONALIZACAO = 2,
  PRECIFICACAO = 3,
  EDITORIAL = 4,
  SEO = 5
}

export type InternalUserInfo = {
  id: number
  name: string
  cityId: number
  email: string
  cpf: number | string
  phone: string
  mobile: string
  imageId: number
  birthDate: string
  address: string
  number: string
  complement: string
  zipcode: string
  keycloakId: number | string
  neighborhood: string
  consent: boolean
  userSiteStatus: boolean
  userInternalStatus: boolean
  permissions: InternalUserPermissions[]
}

const baseURL = process.env.REACT_APP_ADMIN_API

export const searchExternalUsers = async (
  page: number,
  pageSize: number,
  search?: string
) => {
  const response = await request.get<unknown, ResponseInterceptor<any>>(
    `/usersite/v1.0/external`,
    {
      baseURL,
      params: {
        page: page,
        ...(pageSize ? { pageSize: pageSize } : {}),
        ...(search ? { search: search } : {})
      }
    }
  )

  return response.data
}

export const editExternalUser = async (
  userId: number,
  body: EditDataRequest
) => {
  const response = await request.post(`/usersite/v1.0/edit/${userId}`, body, {
    baseURL
  })

  return response
}

export const deleteExternalUser = async (userId: number) => {
  const response = await request.delete(`/usersite/v1.0/deactivate/${userId}`, {
    baseURL
  })

  return response
}

export const searchInternalUsers = async (search?: string) => {
  const response = await request.get<unknown, ResponseInterceptor<any>>(
    `/userinternal/v1.0`,
    {
      baseURL,
      params: {
        ...(search ? { search: search } : {})
      }
    }
  )

  return response.data
}

export const createInternalUser = async (body: any) => {
  const response = await request.post(`/userinternal/v1.0`, body, {
    baseURL
  })

  return response
}

export const editInternalUser = async (
  userId: number,
  status: boolean,
  body: any
) => {
  const response = await request.put(
    `/userinternal/v1.0/${userId}?status=${status}`,
    body,
    {
      baseURL
    }
  )

  return response
}

export const deleteInternalUser = async (userId: number) => {
  const response = await request.delete(
    `/userinternal/v1.0/deactivate/${userId}`,
    {
      baseURL
    }
  )

  return response
}

export const getInternalUserPermissions = async () => {
  const response = await request.get(`/userinternal/v1.0/permissions`, {
    baseURL
  })

  return response
}

export const getInternalUserInfo = async () => {
  const response = await request.get<
    unknown,
    ResponseInterceptor<InternalUserInfo>
  >(`/userinternal/v1.0/find/me`, {
    baseURL
  })

  return response
}
