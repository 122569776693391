/* eslint-disable @typescript-eslint/no-explicit-any */
import AuthComponent from 'auth/AuthComponent'
import ContainerAdmin from 'components/ContainerAdmin'
import _ from 'lodash'
import { lazy } from 'react'
import { PermissionsType } from 'services/users'

// PAGES
const Home = lazy(() => import('pages/home'))
const NotFound = lazy(() => import('pages/404'))
// AUTH_PAGES
const ActivityLog = lazy(() => import('pages/activityLog'))
const NewArticle = lazy(() => import('pages/article/NewArticle'))
const EditArticle = lazy(() => import('pages/article/EditArticle'))
const Blog = lazy(() => import('pages/blog'))
const Channel = lazy(() => import('pages/channel'))
const ExternalUsers = lazy(() => import('pages/externalUsers'))
const InternalUsers = lazy(() => import('pages/internalUsers'))
const HomeCustomization = lazy(() => import('pages/HomeCustomization'))
const PricingPolicy = lazy(() => import('pages/pricingPolicy'))
const Writers = lazy(() => import('pages/writers'))
const SocialMedia = lazy(() => import('pages/socialMedia'))
const ActiveStores = lazy(() => import('pages/activeStores'))
const SeoManagement = lazy(() => import('pages/seoManagement'))
const BrandPage = lazy(() => import('pages/brandPage'))
const BrandDetails = lazy(() => import('pages/brandPage/generalInformation'))

const INITIAL_PATH = '/'

interface IAuthChildren {
  path: string
  element: any
  permissions?: PermissionsType[]
}

const authChildren: IAuthChildren[] = [
  {
    path: '/politicas-de-precificacao',
    element: <PricingPolicy />,
    permissions: [PermissionsType.PRECIFICACAO]
  },
  {
    path: '/seo-paginas-estaticas',
    element: <SeoManagement />,
    permissions: [PermissionsType.SEO]
  },
  {
    path: '/seo-paginas-de-marcas',
    element:  <BrandPage />,
    permissions: [PermissionsType.SEO]
  },
  {
    path: '/seo-paginas-de-marcas/:brandName',
    element: <BrandDetails />,
    permissions: [PermissionsType.SEO]
  },
  {
    path: '/registro-de-atividades',
    element: <ActivityLog />
  },
  {
    path: '/',
    element: <Home />
  },
  {
    path: '/usuarios-externos',
    element: <ExternalUsers />,
    permissions: [PermissionsType.USUARIOS]
  },
  {
    path: '/usuarios-internos',
    element: <InternalUsers />,
    permissions: [PermissionsType.USUARIOS]
  },
  {
    path: '/personalizar-home',
    element: <HomeCustomization />,
    permissions: [PermissionsType.PERSONALIZACAO]
  },
  {
    path: '/editorial-canais',
    element: <Channel />,
    permissions: [PermissionsType.EDITORIAL]
  },
  {
    path: '/editorial-escritores',
    element: <Writers />,
    permissions: [PermissionsType.EDITORIAL]
  },
  {
    path: '/editorial-blog',
    element: <Blog />,
    permissions: [PermissionsType.EDITORIAL]
  },
  {
    path: '/editorial-blog/materia/',
    element: <NewArticle />,
    permissions: [PermissionsType.EDITORIAL]
  },
  {
    path: '/editorial-blog/materia/:id',
    element: <EditArticle />,
    permissions: [PermissionsType.EDITORIAL]
  },
  {
    path: '/personalizar-redes-sociais',
    element: <SocialMedia />,
    permissions: [PermissionsType.PERSONALIZACAO]
  },
  {
    path: '/personalizar-lojas-ativas',
    element: <ActiveStores />,
    permissions: [PermissionsType.PERSONALIZACAO]
  }
]

const authRoute = [
  {
    path: INITIAL_PATH,
    element: (
      <AuthComponent>
        <ContainerAdmin />
      </AuthComponent>
    ),
    children: [...authChildren]
  }
]

const route404 = [
  {
    path: '*',
    element: (
      <AuthComponent>
        <NotFound />
      </AuthComponent>
    )
  }
]

export const routesWithPermissions = (
  userPermissions = [],
  userStatus = false
) => {
  const profilePermissions = userPermissions?.map(({ id }) => id)

  const buildRoutes = authChildren.filter((route) => {
    if ('permissions' in route) {
      const hasPermission = !_.isEmpty(
        _.intersection(profilePermissions, route.permissions)
      )

      if (hasPermission && userStatus) {
        return route
      }

      return false
    }

    return route
  })

  const buildedRoutes = [
    {
      path: INITIAL_PATH,
      element: (
        <AuthComponent>
          <ContainerAdmin />
        </AuthComponent>
      ),
      children: [...buildRoutes]
    }
  ]

  return [...buildedRoutes, ...route404]
}

const routes = [...authRoute, ...route404]

export default routes
