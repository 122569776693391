import Spinner from 'components/Spinner/Spinner'
import { useUserProfile } from 'context/UserProfileContext'
import request from 'helpers/request'
import React from 'react'
import { getInternalUserInfo } from 'services/users'
import { getToken, login } from './AuthService'

interface IAuth {
  children: React.ReactNode
}

const AuthComponent = ({ children }: IAuth) => {
  const [isLogged, setLogged] = React.useState(false)
  const { setUserProfile } = useUserProfile()

  const tryLogin = React.useCallback(async () => {
    const isAuthenticated = await login()

    const token = getToken()

    if (isAuthenticated) {
      request.defaults.headers.common.Authorization = `Bearer ${token}`

      setLogged(true)

      const { data } = await getInternalUserInfo()

      setUserProfile(data)
    }
  }, [])

  React.useEffect(() => {
    tryLogin()
  }, [tryLogin])

  if (!isLogged) {
    return <Spinner />
  }

  return <>{children}</>
}

export default AuthComponent
