import axios, { AxiosInstance, AxiosResponse } from 'axios'

export type ResponseInterceptor<T> = {
  success: boolean
} & AxiosResponse<T>

const request = axios.create()

request.interceptors.response.use((response) => {
  return { ...response, success: true }
})

export default request as AxiosInstance
