import { CircularProgress } from '@material-ui/core'
import * as Style from './style'

const Spinner = () => {
  return (
    <Style.CenteredLoading>
      <CircularProgress color="inherit" />
    </Style.CenteredLoading>
  )
}

export default Spinner
