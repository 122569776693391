import MomentUtils from '@date-io/moment'
import { withTransaction } from '@elastic/apm-rum-react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { AlertContainer } from '@sagacarmi/seminovos-design-system/core/lab/alert'
import {
  DSThemeProvider,
  defaultTheme
} from '@sagacarmi/seminovos-design-system/core/theme'
import UserContext, { useUserProfile } from 'context/UserProfileContext'
import 'global/GlobalStyles.css'
// import { getApmAgent } from 'helpers/apm'
import moment from 'moment'
import 'moment/locale/pt-br'
import React, { Suspense } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.min.css'
import { routesWithPermissions } from './config/routes'

const queryClient = new QueryClient()

function App() {
  const locale = 'pt-br'

  moment.locale(locale)

  return (
    <QueryClientProvider client={queryClient}>
      <DSThemeProvider theme={defaultTheme}>
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={locale}
        >
          <UserContext>
            <BrowserRouter>
              <Suspense fallback={<p>Carregando...</p>}>
                <Routers />
              </Suspense>
            </BrowserRouter>
          </UserContext>
          <AlertContainer />
        </MuiPickersUtilsProvider>
      </DSThemeProvider>
    </QueryClientProvider>
  )
}

export default withTransaction('app', 'component')(App)

function Routers() {
  const { userPermissions, userProfile } = useUserProfile()
  const [withPermissions, setWithPermissions] = React.useState([])

  React.useEffect(
    () =>
      setWithPermissions(
        routesWithPermissions(userPermissions, userProfile?.userInternalStatus)
      ),
    [userPermissions]
  )

  const elements = useRoutes([...withPermissions])

  return elements
}
