import { styled } from '@material-ui/core'
import { StyledThemeProp } from '@sagacarmi/seminovos-design-system/core/theme'

export const HeaderWrapper = styled('div')(
  ({ theme: { palette } }: StyledThemeProp) => ({
    gridColumn: '1/3',
    position: 'sticky',
    top: '0',
    zIndex: 5,
    backgroundColor: palette.neutral.black.white1,
    borderBottom: `1px solid ${palette.neutral.grey.gray5}`
  })
)

export const Header = styled('header')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '60px',
  padding: '0 32px'
})

export const NavLink = styled('ul')(
  ({ theme: { typography } }: StyledThemeProp) => ({
    display: 'flex',
    gap: '36px',
    alignItems: 'center',

    '& li': {
      listStyle: 'none',

      '& a': {
        ...typography.subtitle2,
        fontFamily: 'PoppinsRegular'
      }
    },

    '& button': {
      height: '48px'
    }
  })
)

export const UserSpace = styled('div')(
  ({ theme: { palette } }: StyledThemeProp) => ({
    borderLeft: `1px solid ${palette.neutral.grey.gray5}`,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  })
)

export const UserName = styled('div')(
  ({ theme: { typography } }: StyledThemeProp) => ({
    ...typography.body.desktop.bold3,
    marginLeft: '24px'
  })
)
