/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { InternalUserInfo, InternalUserPermissions } from 'services/users'

interface IUserProfile {
  userProfile: InternalUserInfo
  setUserProfile: (value: InternalUserInfo) => void
  userPermissions: InternalUserPermissions[]
}

const UserProfileContext = React.createContext({} as IUserProfile)

const UserProfileProvider = ({ children }: any) => {
  const [userProfile, setUserProfile] = React.useState<InternalUserInfo>(null)

  const [userPermissions, setUserPermissions] =
    React.useState<InternalUserPermissions[]>(null)

  const getUserPermissions = () => {
    if (userProfile) {
      setUserPermissions([...userProfile.permissions])
    }
  }

  React.useEffect(() => {
    getUserPermissions()
  }, [userProfile])

  const values = {
    userProfile,
    setUserProfile,
    userPermissions
  }

  return (
    <UserProfileContext.Provider value={values}>
      {children}
    </UserProfileContext.Provider>
  )
}

const withUserProfileContext = (Component: any) => (props: any) =>
  (
    <UserProfileProvider>
      <Component {...props} />
    </UserProfileProvider>
  )

const useUserProfile = () => React.useContext(UserProfileContext)

export {
  UserProfileContext,
  UserProfileProvider as default,
  useUserProfile,
  withUserProfileContext
}
