import { Accordion, styled } from '@material-ui/core'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { StyledThemeProp } from '@sagacarmi/seminovos-design-system/core/theme'

export const StyledAccordion = styled(Accordion)(
  ({ isSideBar }: { isSideBar?: boolean }) => ({
    boxShadow: isSideBar && 'none'
  })
)

export const StyledAccordionSummary = styled(MuiAccordionSummary)(
  ({
    theme: { palette },
    maxHeight,
    isSideBar
  }: StyledThemeProp & {
    maxHeight: string
    isSideBar?: boolean
  }) => ({
    color: palette.neutral.grey.gray1,
    maxHeight: maxHeight || '64px',
    padding: isSideBar && 0,
    minHeight: '40px',
    '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
      transform: 'rotate(90deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    },

    '& .MuiAccordionSummary-expandIcon ': {
      borderRadius: '0',
      marginRight: isSideBar && '10px'
    },

    '& li': {
      fontWeight: isSideBar && 400
    }
  })
)

export const ContentSummary = styled('div')(
  ({
    theme: { typography },
    isActive
  }: StyledThemeProp & { isActive?: boolean }) => ({
    ...typography.body.desktop.bold1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    borderLeft: isActive ? '4px solid #000000' : '4px solid transparent',

    '& button': {
      maxWidth: '200px'
    }
  })
)

export const Footer = styled('div')({
  padding: '10px 0 10px 16px',
  width: '177px'
})
