import { styled } from '@material-ui/core'
import { StyledThemeProp } from '@sagacarmi/seminovos-design-system/core/theme'

export const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: '260px 1fr',
  gridTemplateRows: '60px auto'
})

export const Main = styled('main')(
  ({ theme: { palette } }: StyledThemeProp) => ({
    padding: '24px 32px',
    backgroundColor: palette.neutral.black.white1,
    paddingLeft: '24px'
  })
)
