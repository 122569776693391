import { AccordionDetails, Divider } from '@material-ui/core'
import { Icon } from '@sagacarmi/seminovos-design-system/core'
import { getIcon } from 'helpers/icons'
import { ReactNode } from 'react'
import * as Style from './style'

type AccordionProps = {
  summaryTitle: ReactNode
  children: ReactNode
  maxHeight?: string
  isSideBar?: boolean
  isActive?: boolean
  footer?: ReactNode
}

const CustomAccordionComponent = ({
  summaryTitle,
  children,
  maxHeight,
  isSideBar,
  isActive,
  footer
}: AccordionProps) => {
  return (
    <div>
      <Style.StyledAccordion
        isSideBar={isSideBar}
        TransitionProps={{ unmountOnExit: true }}
      >
        <Style.StyledAccordionSummary
          expandIcon={<Icon icon={getIcon('ChevronRightIcon')} />}
          maxHeight={maxHeight}
          isSideBar={isSideBar}
        >
          <Style.ContentSummary isActive={isActive}>
            {summaryTitle}
          </Style.ContentSummary>
        </Style.StyledAccordionSummary>
        {footer && <Divider />}
        <AccordionDetails>{children}</AccordionDetails>
        {footer && (
          <>
            <Divider />
            <Style.Footer>{footer}</Style.Footer>
          </>
        )}
      </Style.StyledAccordion>
    </div>
  )
}

export default CustomAccordionComponent
