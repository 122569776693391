/* eslint-disable array-callback-return */
import { ButtonBase } from '@material-ui/core'
import { Icon } from '@sagacarmi/seminovos-design-system/core'
import Logo from 'assets/icon/powered-logo.png'
import { logout } from 'auth/AuthService'
import { links } from 'constants/sidebarLink'
import { useUserProfile } from 'context/UserProfileContext'
import { getIcon } from 'helpers/icons'
import _ from 'lodash'
import SideBarComponent from './SideBarComponent'
import * as Style from './style'

const SideBarContainer = () => {
  const { userPermissions, userProfile } = useUserProfile()

  const mappedPermissionIds = userPermissions?.map(({ id }) => id)

  return (
    <Style.SideBarWrapper>
      <Style.CustomList>
        <>
          {links.map((link) => {
            if (
              link.permissions &&
              userProfile?.userInternalStatus &&
              !_.isEmpty(_.intersection(mappedPermissionIds, link.permissions))
            ) {
              return <SideBarComponent key={link.title} {...link} />
            }
            if (!link.permissions) {
              return <SideBarComponent key={link.title} {...link} />
            }
          })}
        </>
        <Style.Signout onClick={() => logout()}>
          <Icon icon={getIcon('LogOutIcon')} />
          <ButtonBase>Sair</ButtonBase>
        </Style.Signout>
      </Style.CustomList>
      <Style.Powered>
        <div>
          Powered by <img src={Logo} />
        </div>
      </Style.Powered>
    </Style.SideBarWrapper>
  )
}

export default SideBarContainer
